// project: a17170vwtiguan-xtxv
// new measurement id: G-SRLNRRXZYK
// secret: RufTmyGpQKGV89GoJCH_zg
// ga: 459026719
import TemplateSocialView from "../views/TemplateSocialView.svelte";
import generateUniqueSessionId from '../lib/generateUniqueSessionId.js'

const buttonBackground = "#043666";
const buttonText = "#ffffff";
const mainUrl = 'https://www.vw.co.za/en/forms/testdrive.html';
const slug = "aefe7ce9c43f3bbe70d545864b69ed70|1b0e8faa58cef5831cb270359b50f81b4e48ae9606b3befdd3aa3e2ddb055a804f39643684ac1e224bca5c79b8f4aac2009e40a2bbc543";
const buttons = [
    {title: "What’s new?"},
    {title: "Discover the Tiguan", url: "https://www.vw.co.za/en/models/tiguan.html#MOFA"},
    {title: "Book a test drive", url: mainUrl},
]
//.sort(() => Math.random() - 0.5);
const imageFolderName = "17170-vw-tiguan";

const checkValue = (value, defaultValue) => {
  return (typeof(value) !== 'undefined') ? value : defaultValue
}
const darkMode = false
const styleParams = {
  mainColor: buttonBackground,
  secondaryColor: buttonText,
}
const style =           {
  // basic colors
  brandColor: checkValue(styleParams.brandColor, '#ffffff'),
  secondaryBrandColor: checkValue(styleParams.mainColor, 'lightblue'),
  chatBackgroundColor: (darkMode) ? '#000000' : '#ffffff', // the background color in the actual chat
  // cards
  cardBackground: (darkMode) ? '#333333' : '#ebe8e8', // the card background
  cardTextColor: checkValue(styleParams.cardTextColor, (darkMode) ?  '#ffffff' : '#000000'),// cards
  borderRadius: '8px', // cards + buttons
  imagesHaveBorders: true,
  // Users
  userTextColor: '#ffffff',// user options
  userBackgroundColor: "#959595",
  // Text Input
  UIButtonBackgroundColor: '#959595', // textInput send` button
  UIButtonStrokeColor: 'white', // textInput send button
  textInputColor: '#666666', // textInput options
  textInputBorderRadius: '16px', // textInput options
  typingIndicator: (darkMode) ? 'rgb(200,200,200)': 'rgb(55, 55, 55)',
  headerBackgroundColor: buttonBackground,
}

const config = {
  darkMode: false,
  url: 'https://adchat-ai.uc.r.appspot.com/b',
  productionMode: true,
  headerLogoURL: "mainUrl",
  headerButtonCopy: "Book a test drive",
  hasHeaderButton: true,
  headerButtonPostback: false,
  language: 'EN',
  logoURL: `images/${imageFolderName}/logo-left.png`,
  // rightLogoURL: `images/${imageFolderName}/logo-right.jpg`,
  roundLogo: false,
  headerBackgroundPosition: '16px 50%',
  introPostback: buttons[0].title,
  firstFrameClickShouldChat: true,
  buttonInFirstFrame: true,
  chatHeightOffset: 80,
  slugMode: true,
  slug: slug,
  firstFrameExitMode: false,
  // firstFrameExitURL: mainUrl,
  chatTimingMS: 600,
  imagesHaveBorders: true,
  ySpaceBetweenCards: '6px',
  sessionId: generateUniqueSessionId(),
  platform: 'landing-page-en',
  hasInput: false,
  conversationHasInput: false,
  version: '1.0',
  headerExitURL: mainUrl,
  inputConfig: {
    textInputWidth: '80%',
    promptCopy: ""
  },
  firstFrame: {
    inputConfig: {
      textInputWidth: '80%',
      promptCopy: ""
    },
  },
  pixels: {
    "engaged-once":"https://ad.doubleclick.net/ddm/activity/src=11598943;type=invmedia;cat=volks0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?",
    "exit-external":"https://ad.doubleclick.net/ddm/activity/src=11598943;type=invmedia;cat=volks00;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?"
  },
  exits: {
    "https://www.vw.co.za/en/forms/testdrive.html": "Book A Test Drive",
    "https://www.vw.co.za/en/models/tiguan.html#MOFA": "Model Page",
    "https://www.vw.co.za/en/models/tiguan.html#MOFA": "Build Yours"
  },
  exitRedirects: {
  },
  simulatedConversation: [
      {
          type: 'image',
          src: `./images/${imageFolderName}/first-frame.mp4`,
          imageHeight: 320,
      },
      {
          type: 'brandResponse',
          text: "From your child’s birthday party, to that meeting that could have been an email, **your mind is on more than just the road.**",
      },
      {
          type: 'brandResponse',
          text: "**That’s why our mind is on you** with the new Tiguan’s IQ.DRIVE safety system, massage seats, and more. Starting from R651,500.",
      },
      {
          type: 'quickReplies',
          text: "Find out more about the new Tiguan below.",
          size: 'large',
          buttons: buttons
      }
  ],
} 





const app = new TemplateSocialView({
	target: document.body,
	props: {
		style: style,
		config: config,
	}
});

export default app;
